import Header from "../../components/Header";
import HeaderImage from "../../images/zz.jpg";
import { products} from "../../data";
import Product from "../../components/Product";
import "./products.css";

const Products = () => {
  return (
    <>
      <Header title="Our Products" image={HeaderImage}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam sapiente
        quibusdam error sunt qui expedita aspernatur?
      </Header>
      <section className="products">
        <div className="container products_container">
          {
            products.map(({id, image, name, job}) =>{
             return  <Product key={id} image={image} name={name} job = {job}/>
               
            })
          }
        </div>
      </section>
    </>
  );
};

export default Products;
