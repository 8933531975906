import Header from "../../components/Header";
import HeaderImage from "../../images/headerabout.jpg";
import StoryImage from "../../images/gg.jpg";
import VisionImage from "../../images/ff.jpg"
import MissionImage from "../../images/dd.jpg"
import "./about.css";

const about = () => {
  return (
    <>
      <Header title="About Us" image={HeaderImage}>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde minima
        molestias cum?
      </Header>

      <section className="about_story">
        <div className="container about_story-container">
          <div className="about_section-image">
            <img src={StoryImage} alt="Our Story Image" />
          </div>
          <div className="about_section-content">
            <h1>Our Story</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum sed
              omnis ipsam! Dolores ipsum explicabo recusandae numquam obcaecati
              illo blanditiis maxime quidem sit, at, atque, modi tenetur.
              Inventore perspiciatis necessitatibus quam consectetur cupiditate
              alias sit ut, asperiores velit adipisci eveniet, exercitationem
              voluptatibus dolor? Veniam repudiandae perspiciatis fuga vitae ab.
              Magnam, voluptatem.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. At iste
              officiis molestiae a quia reiciendis quod? Odit fugiat mollitia
              veritatis consectetur amet, vitae accusantium itaque, omnis vel
              delectus a repellendus voluptates magni ipsam dicta?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit quis
              animi ex, earum nostrum repellendus illum repellat alias! Officia
              earum delectus porro quia minus possimus consectetur reprehenderit
              asperiores dolorem!
            </p>
          </div>
        </div>
      </section>

      <section className="about_vision">
        <div className="container about_vision-container">
          <div className="about_section-content">
            <h1>Our Vision</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum sed
              omnis ipsam! Dolores ipsum explicabo recusandae numquam obcaecati
              illo blanditiis maxime quidem sit, at, atque, modi tenetur.
              Inventore perspiciatis necessitatibus quam consectetur cupiditate
              alias sit ut, asperiores velit adipisci eveniet, exercitationem
              voluptatibus dolor? Veniam repudiandae perspiciatis fuga vitae ab.
              Magnam, voluptatem.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. At iste
              officiis molestiae a quia reiciendis quod? Odit fugiat mollitia
              veritatis consectetur amet, vitae accusantium itaque, omnis vel
              delectus a repellendus voluptates magni ipsam dicta?
            </p>
          </div>
          <div className="about_section-image">
            <img src={VisionImage} alt="Our Vision Image" />
          </div>
        </div>
      </section>

      <section className="about_mission">
        <div className="container about_mission-container">
          <div className="about_section-image">
            <img src={MissionImage} alt="Our Mission Image" />
          </div>
          <div className="about_section-content">
            <h1>Our Mission</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum sed
              omnis ipsam! Dolores ipsum explicabo recusandae numquam obcaecati
              illo blanditiis maxime quidem sit, at, atque, modi tenetur.
              Inventore perspiciatis necessitatibus quam consectetur cupiditate
              alias sit ut, asperiores velit adipisci eveniet, exercitationem
              voluptatibus dolor? Veniam repudiandae perspiciatis fuga vitae ab.
              Magnam, voluptatem.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. At iste
              officiis molestiae a quia reiciendis quod? Odit fugiat mollitia
              veritatis consectetur amet, vitae accusantium itaque, omnis vel
              delectus a repellendus voluptates magni ipsam dicta?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit quis
              animi ex, earum nostrum repellendus illum repellat alias! Officia
              earum delectus porro quia minus possimus consectetur reprehenderit
              asperiores dolorem!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default about;
