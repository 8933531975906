import Header from '../../components/Header'
import HeaderImage from '../../images/aa.webp'
import {MdEmail} from 'react-icons/md'
import { FaLinkedin } from 'react-icons/fa'
import {IoLogoWhatsapp} from 'react-icons/io'




import './contact.css'

const contact = () => {
  return (
    <>
    <Header title="Contact Us" image={HeaderImage}>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem officiis dolor, vitae maxime facilis enim facere.
    </Header>
    <section className='contact'>
      <div className="container contact_container">
        <div className="contact_wrapper">
          <a href="mailto:apoorvsharma070801@gmail.com" target="_blank" rel="noreferrer noopener"><MdEmail/></a>
          <a href="https://www.linkedin.com/in/stutitiwari161/" target="_blank" rel="noreferrer noopener"><FaLinkedin/></a>
          <a href="https://wa.me/+919818127217" target="_blank" rel="noreferrer noopener"><IoLogoWhatsapp/></a>
        </div>
      </div>
    </section>
    </>
  )
}

export default contact
