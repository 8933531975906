import { Link, Links } from "react-router-dom";
import Image from "../images/water-purifiers.webp";

const MainHeader = () => {
  return (
    <header className="main_header">
      <div className="container main_header-container">
        <div className="main_header-left">
          <h4>#TheNameYouCanTrust</h4>
          <h1>Ai Industries </h1>
          <p>
            Welcome to Ai Industries, your trusted partner in cutting-edge RO
            and home appliances. With a strong presence in multiple cities
            across India, we bring innovation to your doorstep. Out commitment
            to quality, performance and customer satisfaction sets up apart.
            Explore our wide range of products and experience the future of home
            technology with Ai Industries.
          </p>
          <Link to="/contact" className="btn lg">
            Contact Us
          </Link>
        </div>
        <div className="main_header-right">
          <div className="main_header-circle"></div>
          <div className="main_header-image">
            <img src={Image} alt="Main Header Image" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;

// 1.48 se cont
