import Header from "../../components/Header";
import HeaderImage from "../../images/oo.jpg";

import "./gallery.css";

const Gallery = () => {
  const galleryLength = 15;
  const images = []

  for(let i=1; i<= galleryLength; i++){
    images.push(require(`../../images/gallery${i}.jpg`))
  }
  



  return (
    <>
      <Header title="Our Gallery" image={HeaderImage}>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore,
        praesentium! Ad iste excepturi totam, minus, cumque sapiente incidunt
      </Header>
      <section className="gallery">
        <div className="container gallery_container">
          {
            images.map((images,index) =>{
              return <article key={index}>
                <img src={images} alt={`Gallery Image ${index +1}`} />
              </article>
            })
          }
        </div>
      </section>
    </>
  );
};

export default Gallery;
