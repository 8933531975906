import Card from '../UI/Card'

const Product = ({image, name, job}) => {
  return (
    <Card className="product">
        <div className="product_img">
            <img src={image} alt={name} />
        </div>
        <h3>{name}</h3>
        <p>{job}</p>
        
        
    </Card>
  )
}

export default Product
