import {SiOpenaigym} from 'react-icons/si'



export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Gallery",
        path: '/gallery'
    },
    {
        name: "Products",
        path: '/products'
    },
    {
        name: "Contact",
        path: '/contact'
    }
]







export const programs = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "ACT-2",
        info: "Introducing our best-seller, the ultimate RO water purifier designed to provide you with pure, healthy and great tasting water. This state-of-the-art machine combines sleek design with advanced filtration technology, ensuring your family's well-being is our top notch prority.",
        path: "/programs/111"
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "ACT-5",
        info: "ACT-5 is our one of the best-selling RO model. Unmatched in performance and design, the ACT-5 offers top notch design with the best quality of water, ensuring your family enjoys the purest, healthiest water possible. Join countless satisfied customers who have made the ACT-5 their top choice for clean and delicious water.",
        path: "/programs/222"
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "Filters/Spare parts",
        info: "Discover out best-selling water filter and a comprehensive selection of top-quality spare parts. Our best-seller ensures impeccable water quality, and our geniune spare parts gurantee long-lasting performance and reliability. Tust in the best for your water filtration needs, available now with a range of accessories to keep your system running smoothly.",
        path: "/programs/333"
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Chimney",
        info: "Experience the pinnacle of the kitchen ventilation with our best-selling chimney. Designed for maximum efficiency and style, our top-rated chimney removes cooking odors and keep your kitchen fresh and inviting. Elevate your culinary space with the best in the business-available now for smoking-free cooking experience.",
        path: "/programs/444"
    }
]








export const values = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Quality",
        desc: "Commit to provide high quality products that meet or exceed industry standards."
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Customer-Centric",
        desc: "Prioritize customer satisfaction by offering excellent service, support and user-friendly products."
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "Affordability",
        desc: "Strive to make clean and safe drinking water accessible to a wide range of consumers through competitive pricing."
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Integrity",
        desc: "Uphold ethical standards in all business dealings, from products advertising to customer interaction."
    }
]









export const faqs = [
    {
        id: 1,
        question: "What is a copper filter in RO?",
        answer: "Copper is one of the important minerals that the human body needs. You can buy a copper water filter and use it every day to enjoy the benefits. Copper filters are effective at killing bacteria found in water."
    },
    {
        id: 2,
        question: "What are the benefits of copper water?",
        answer: "Advantages of Drinking Water From a Copper Bottle- Anemia is kept at bay with copper. Thyroid gland function is balanced. Hypertension is controlled. It can be used to treat arthritis. It is beneficial to the heart. Reduces the effects of aging on the body. Beneficial to the digestive system."
       
       
    },
    {
        id: 3,
        question: "When you should buy a water purifier?",
        answer: " You should buy an RO purifier only if the water to be purified has a high TDS level. The Bureau of Indian Standards (BIS) has specified the maximum TDS limit for safe drinking water as 300 ppm."
    },
    {
        id: 4,
        question: "Which is the best water purifier with minerals?",
        answer: "Ai Industries advanced pro mineral RO + UV water purifier is the best mineral water purifier. It adds essential minerals like Calcium and Magnesium to RO purified water. It has 6 stages of RO+UV purification to remove harmful viruses, bacteria, chemicals, and heavy metals."
    },
    {
        id: 5,
        question: "Which water is best for drinking?",
        answer: "Like distilled water, purified water is a great option if your water source is contaminated. Having said that, many households in India purify tap water, so you're basically drinking purified water every time you fill a cup."
    },
    {
        id: 6,
        question: "Why is staying hydrated important?",
        answer: "Water is necessary for a multitude of functions, including controlling body temperature, transporting nutrients to cells, avoiding infections, keeping joints lubricated, and maintaining normal organ function. Being hydrated improves cognition, sleep quality, and happiness."
    }
]








export const testimonials = [
    {
        id: 1,
        name: "Shreya Jain",
        quote: "This Ro water purifier is an absolute game-changer. It not only ensures that my family has access to clean and safe drinking water but also does so with incredible speed and efficiency. The water tastes remarkably pure, and I can see the difference in the health of my family since we started using it. It's easy to maintain, and the customer support provided by AI Industries is exceptional.",
        job: "Chartered Accountant",
        avatar: require("./images/avatar1.jpg")
    },
    {
        id: 2,
        name: "Sarthak Gupta",
        quote: "I can't express how delighted I am with my AI Industries Ro Water Purifier. It has completely transformed the way my family consumes water. The taste is remarkably pure, and we've noticed an improvement in our overall health. The installation was hassle-free, and the after-sales service has been impeccable. I highly recommend this product to anyone looking for clean and safe drinking water.",
        job: "Software Egineer",
        avatar: require("./images/avatar2.jpg")
    },
    {
        id: 3,
        name: "Kusum Kaushik",
        quote: "The AI Industries Chimney has transformed my kitchen into a more pleasant and efficient space. Its powerful suction capability removes cooking odors, smoke, and grease effortlessly, leaving the air in my kitchen clean and fresh. Even when I'm preparing aromatic dishes, I no longer worry about lingering smells.",
        job: "University Lecturer",
        avatar: require("./images/avatar3.jpg")
    },
    {
        id: 4,
        name: "Sunita Sharma",
        quote: "This Ro water purifier from AI Industries has truly impressed me with its performance. It operates quietly, and the water flow rate is excellent. The installation team was professional and quick. The filters are easy to replace, and the cost of maintenance is reasonable. I appreciate the attention to detail in the design, and it has seamlessly integrated into my kitchen. It's a must-have for any health-conscious household.",
        job: "Housewife",
        avatar: require("./images/avatar4.jpg")
    },
    {
        id: 5,
        name: "Archit Tomar",
        quote: "The AI Industries Ro Water Purifier has been a life-saver for my family. Living in an area with water quality issues, this purifier has provided us with peace of mind. It efficiently filters out impurities and contaminants, and the water tastes fantastic. The smart monitoring system is a brilliant addition, allowing us to keep track of filter status and water quality. We couldn't be happier with this investment in our health.",
        job: "Pharmacist",
        avatar: require("./images/avatar5.jpg")
    }
]
















const Trainer1 = require('./images/trainer1.jpg')
const Trainer2 = require('./images/trainer2.jpg')
const Trainer3 = require('./images/trainer3.jpg')
const Trainer4 = require('./images/trainer4.jpg')
const Trainer5 = require('./images/trainer5.jpg')
const Trainer6 = require('./images/trainer6.jpg')


export const products= [
    {
        id: 1,
        image: Trainer1,
        name: 'John Doe',
        job: 'Aerobic Trainer',
       
    },
    {
        id: 2,
        image: Trainer2,
        name: 'Daniel vinyo',
        job: 'Speed Trainer',
    },
    {
        id: 3,
        image: Trainer3,
        name: 'Edem Quist',
        job: 'Flexibility Trainer',
    },
    {
        id: 4,
        image: Trainer4,
        name: 'Shatta Wale',
        job: 'Body Composition Trainer',
    },
    {
        id: 5,
        image: Trainer5,
        name: 'Diana Ayi',
        job: 'Circuit Trainer',
    },
    {
        id: 6,
        image: Trainer6,
        name: 'Wayne Carter',
        job: 'Physical Intelligence Trainer',
    }
]