import Image from '../images/values4.jpg'
import SectionHead from './SectionHead';
import {GiCutDiamond} from 'react-icons/gi'
import {values} from '../data'
import Card from '../UI/Card'

const Values = () => {
  return (
    <section className='values'>
        
    <div className='container values_container'>
        <div className="values_left">
            <div className="values_image">
                <img src={Image} alt="Values Image" />
            </div>
        </div>
        <div className="values_right">
            <SectionHead icon={<GiCutDiamond/>} title="Our Values"/>
            <p>
                We are dedicated to delivering top-notch Reverse Osmosis (RO) systems, pushing the boundaries of technology, and fostering transparent, long-lasting relationships with our customers. Our commitment to excellence and the environment sets us apart, making us your reliable choice for RO solutions.
            </p>
            <div className="values_wrapper">
                {
                    values.map(({id, icon, title, desc}) =>{
                        return <Card key={id} className="values_value">
                            <span>{icon}</span>
                            <h4>{title}</h4>
                            <small>{desc}</small>
                        </Card>
                    })
                }
            </div>
        </div>
    </div>
    </section>
  );
};

export default Values;
